import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { history } from 'arcadia-common-fe';
import {
  getBrands,
  getBrandsSuccess,
  getBrandsError,
  postBrand,
  mergeBrandDialogForm,
  setBrandDialogForm,
  putBrand,
  putBrandSuccess,
  executeBrandsAction,
  mergeBrandsDialogAction,
  setBrandsDialogAction,
  executeBrandsActionSuccess,
} from './actions';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  IGetBrandsRequestFilterParams,
  IGetBrandsResponseBody,
} from '../types';
import {
  getBrandsRequest,
  postBrandRequest,
  putBrandRequest,
  enableBrandRequest,
  disableBrandRequest,
  removeBrandRequest,
} from '../api';
import { BrandAction } from '../../../types/brand';

function* handleRefreshBrand() {
  yield put(getBrands(queryString.parse(window.location.search)));
}

function* handleGetBrandsRequest(requestParams: IGetBrandsRequestFilterParams) {
  const { data } = yield call(getBrandsRequest, requestParams);

  return data;
}

export function* handleGetBrands({ payload }: ReturnType<typeof getBrands>) {
  try {
    const brandsData: IGetBrandsResponseBody = yield handleGetBrandsRequest(payload);

    yield put(getBrandsSuccess(brandsData));
  } catch (error: any) {
    yield handleError(error);
    yield put(getBrandsError());
  }
}

function* handlePostBrand({ payload }: ReturnType<typeof postBrand>) {
  try {
    yield put(mergeBrandDialogForm({
      isLoading: true,
    }));
    yield call(postBrandRequest, payload);
    yield put(setBrandDialogForm());
    yield call(toast.success, 'The brand has been successfully created');
    yield handleRefreshBrand();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeBrandDialogForm({
      isLoading: false,
    }));
  }
}

function* handlePutBrand({ payload }: ReturnType<typeof putBrand>) {
  try {
    yield put(mergeBrandDialogForm({
      isLoading: true,
    }));

    const { data } = yield call(putBrandRequest, payload);

    yield put(putBrandSuccess(data));
    yield put(setBrandDialogForm());
    yield call(toast.success, `The brand ${payload.name} has been successfully updated`);
    yield handleRefreshBrand();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeBrandDialogForm({
      isLoading: false,
    }));
  }
}

function* handleExecuteBrandsAction({ payload }: ReturnType<typeof executeBrandsAction>): any {
  try {
    const { location: { search } } = history;
    const { id, action } = payload;
    let response;

    yield put(mergeBrandsDialogAction({ isLoading: true }));

    switch (action) {
      case BrandAction.enable:
        response = yield call(enableBrandRequest, payload);
        yield call(toast.success, `The brand with id ${id} has been successfully enabled.`);
        yield handleRefreshBrand();
        break;
      case BrandAction.disable:
        response = yield call(disableBrandRequest, payload);
        yield call(toast.success, `The brand with id ${id} has been successfully disabled.`);
        yield handleRefreshBrand();
        break;
      case BrandAction.remove:
        response = yield call(removeBrandRequest, payload);
        yield call(toast.success, `The brand with id ${id} has been successfully removed.`);
        break;
      default:
        break;
    }

    yield put(setBrandsDialogAction());

    if (action === BrandAction.remove) {
      yield put(getBrands(queryString.parse(search)));
    } else {
      yield put(executeBrandsActionSuccess(response.data));
    }
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeBrandsDialogAction({ isLoading: false }));
  }
}

export function* brandsSagas(): any {
  yield all([
    yield takeLatest(
      getBrands,
      handleGetBrands,
    ),
    yield takeLatest(
      postBrand,
      handlePostBrand,
    ),
    yield takeLatest(
      putBrand,
      handlePutBrand,
    ),
    yield takeLatest(
      executeBrandsAction,
      handleExecuteBrandsAction,
    ),
  ]);
}
