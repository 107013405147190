import { createAction } from '@reduxjs/toolkit';
import {
  IGetBrandsRequestFilterParams,
  IGetBrandsResponseBody,
  IBrandActionRequestBody,
  IBrandDialogActionState,
  IBrandDialogFormState,
  IPostBrandRequestBody,
  IPutBrandRequestBody,
  IPutBrandResponseBody,
  IExecuteBrandResponseBody,
} from '../types';
import { BrandAction } from '../../../types/brand';

interface IExecuteBrandActionPayload extends IBrandActionRequestBody {
  action: BrandAction;
}

export const postBrand = createAction<IPostBrandRequestBody>('BRANDS/POST_BRAND');

export const getBrands = createAction<IGetBrandsRequestFilterParams>('BRANDS/GET_BRANDS');
export const getBrandsSuccess = createAction<IGetBrandsResponseBody>('BRANDS/GET_BRANDS/SUCCESS');
export const getBrandsError = createAction('BRANDS/GET_BRANDS/ERROR');

export const putBrand = createAction<IPutBrandRequestBody>('BRANDS/PUT_BRAND');
export const putBrandSuccess = createAction<IPutBrandResponseBody>('BRANDS/PUT_BRAND/SUCCESS');

export const setBrandDialogForm = createAction<Partial<IBrandDialogFormState> | undefined>('BRANDS/SET_DIALOG_FORM');
export const mergeBrandDialogForm = createAction<Partial<IBrandDialogFormState> | undefined>('BRANDS/MERGE_DIALOG_FORM');

export const setBrandsDialogAction = createAction<Partial<IBrandDialogActionState> | undefined>('BRANDS/SET_DIALOG_ACTION');
export const mergeBrandsDialogAction = createAction<Partial<IBrandDialogActionState> | undefined>('BRANDS/MERGE_DIALOG_ACTION');
export const executeBrandsAction = createAction<IExecuteBrandActionPayload>('BRANDS/EXECUTE_ACTION');
export const executeBrandsActionSuccess = createAction<IExecuteBrandResponseBody>('BRANDS/EXECUTE_ACTION/SUCCESS');
