import {
  IGetBrandsRequestFilterParams,
  IGetBrandsResponseBody,
  IPostBrandRequestBody,
  IPutBrandRequestBody,
  IRemoveBrandRequestBody,
  IBrandActionRequestBody,
} from './types';
import { getUrl, makeRequest } from '../../services/api';

export const postBrandRequest = (data: IPostBrandRequestBody) => {
  const url = getUrl('/brands');

  return makeRequest(url, data, 'POST');
};

export const getBrandsRequest = (filterParams: IGetBrandsRequestFilterParams) => {
  const url = getUrl('/brands', filterParams);

  return makeRequest<IGetBrandsResponseBody>(url);
};

export const putBrandRequest = (data: IPutBrandRequestBody) => {
  const url = getUrl(`/brands/${data.id}`);

  return makeRequest(url, data, 'PUT');
};

export const enableBrandRequest = (brand: IBrandActionRequestBody) => {
  const url = getUrl(`/brands/${brand.id}/enable`);

  return makeRequest(url, brand, 'POST');
};

export const disableBrandRequest = (brand: IBrandActionRequestBody) => {
  const url = getUrl(`/brands/${brand.id}/disable`);

  return makeRequest(url, brand, 'POST');
};

export const removeBrandRequest = (data: IRemoveBrandRequestBody) => {
  const url = getUrl(`/brands/${data.id}`);

  return makeRequest(url, data, 'DELETE');
};
